import React, {Component} from 'react';
import './Email.css'
import Expand from './images/mail_close.svg'
import Collapse from './images/mail_open.svg'

class Email extends Component {

    constructor(props) {
        super(props);
        this.state = {expanded: false};
        this.toggleExpand = this.toggleExpand.bind(this);
    }

    render() {
        if (!this.state.expanded) {
            return <div className="container">
                <div className="wrapper" onClick={this.toggleExpand}>
                    <div className="row">
                        <div>
                            <p className="from">To: {this.props.to}</p>
                            <p className="subject">{this.props.subject}</p>
                        </div>
                        <p className="sentAt">{this.props.sentAt}</p>
                    </div>
                    <img className="icon-button" src={Expand}/>
                </div>
            </div>
        }
        return <div className="container">
            <div className="wrapper" onClick={this.toggleExpand}>
                <div className="row">
                    <div>
                        <p className="from">To: {this.props.to}</p>
                        <p className="subject">{this.props.subject}</p>
                    </div>
                    <p className="sentAt">{this.props.sentAt}</p>
                </div>
                <img className="icon-button" src={Collapse}/>
                <pre>{this.props.text}</pre>
            </div>
        </div>;
    }

    toggleExpand() {
        this.setState({expanded: !this.state.expanded})
    }
}

export default Email;