import React, {Component} from 'react';
import Login from "./Login";
import Logo from "./images/logo.png"
import Email from "./Email";
import ReactDOM from 'react-dom/client';
import './index.css';

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {loggedIn: window.localStorage.getItem("login") === "1"}
        this.setLoggedIn = this.setLoggedIn.bind(this);
    }

    setLoggedIn(loggedIn) {

        window.localStorage.setItem("login", loggedIn ? "1" : "0");
        this.setState({loggedIn: loggedIn});
    }

    render() {
        let content;
        if (!this.state.loggedIn) {
            content = <Login onLogin={this.setLoggedIn}/>
        } else {
            content = <>
                <h3>Willkommen Celina Jones!</h3>
                <button className="logout" onClick={() => this.setLoggedIn(false)}>Logout</button>
                <Email subject="Re: Eingang der Starteinsätze Wayne, Kent und Bond"
                       text="Hallo Herr Tim,
Vielen Dank für die Info, dann freue ich mich jetzt ganz entspannt auf einen spannenden Pokerabend.
-
-
Mit freundlichen Grüßen
Lars Holgersson
Hoteldirektor
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480
-
-
---------------------------
>>    Guten Tag Herr Holgersson,
>>    ich habe ihre telefonische Nachfrage nach den Zahlungseingängen soeben überprüft und kann Ihnen mitteilen, dass nun alle angemeldeten Poker-Teilnehmer ihren Starteinsatz überwiesen haben. Die noch ausstehenden Zahlungen von Linda Wayne, Daniel Kent und Lara Bond wurden heute morgen von unserem System erfasst.
>>    -
>>    Mit freundlichen Grüßen
>>    Martin Tim
>>    Berater der Sparbank Hildesheim
>>    Rathausstraße 21-23,
>>    31134 Hildesheim"
                       to="sparbank-hildesheim@***.de"
                       sentAt="03.06.2022"/>

                <Email
                    subject="Re: Spontane Anmeldung zum Pokerturnier Lara Bond"
                    text="Hallo Herr Watson,
kein Problem, wir freuen uns, dass wir mit Frau Bond eine weitere Pokerikone in unseren Reihen haben. Die Einladung zum Pokerturnier lasse ich Ihnen zukommen.
Wir freuen uns auf einen spannenden Pokerabend.
-
-
Mit freundlichen Grüßen
Lars Holgersson
Hoteldirektor
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480
-
-
------------------
>> Hallo an das Organisationsteam des Royal Golden Pokerturniers,
>> das Turnier findet ja bereits heute Abend statt, trotzdem wollte ich einmal nachfragen, ob es möglich ist Lara Bond noch spontan als Teilnehmerin aufzunehmen. Die Vize-Weltmeisterin 2021 freut sich bereits auf einen schönen Abend.
-
>> Viele Grüße
>> Jens Watson"
                    to="jens-watson@***.de"
                    sentAt="03.06.2022"/>

                <Email
                    subject="Buchung – 2 Nächte – 02.06.2022-04.06.2022"
                    text="Hallo Herr Kent,
hiermit bestätigen wir ihre Buchung in unserem Hotel.
Buchungsdetails:
Daniel Kent
Checkin: 02.06.2022 ab 14:00 Uhr
Checkout: 04.06.2022 bis 10:00 Uhr
2 Nächte Economy Zimmernummer 28
-
-
Wir wünschen Ihnen einen angenehmen Aufenthalt.
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="daniel.kent@***.de"
                    sentAt="01.06.2022"/>
                <Email
                    subject="RE: Kaufanfrage Statue goldener Elefant"
                    text="Hallo Frau Wayne,
nein unser goldener Elefant steht nicht zum Verkauf.
-
-
Mit freundlichen Grüßen
Celina Jones
Hotelrezeptionistin
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480
-
-
------------------
>> Guten Tag,
>> Bei meinem letzten Besuch bei Ihnen ist mir die Statue des goldenen Elefanten in der Hotellobby positiv aufgefallen. Ist diese Statue käuflich erwerbbar? Ich würde Ihnen auch einen angemessenen Preis dafür betahlen.
-
-
>> Mit freundlichen Grüßen
>> Linda Wayne"
                    to="l-wayne@***.de"
                    sentAt="30.05.2022"/>
                <Email
                    subject="RE: Zahlung des Poker-Starteinsatzes"
                    text="Hallo Herr Kent,
melden Sie ich doch am besten telefonisch bei mir direkt. Wir finden sicherlich eine Lösung dafür.
-
-
Mit freundlichen Grüßen
Lars Holgersson
Hoteldirektor
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480
-
-
------------------
>> Guten Tag,
>> ich habe mal eine Frage bezüglich des Starteinsatzes für das Pokerturnier Royal Golden. Wäre es möglich, dass ich die Bezahlung des Einsatzes auf 12 Monatsraten aufteilen könnte?
>> Mit freundlichen Grüßen
>> Daniel Kent"
                    to="daniel.kent@***.de"
                    sentAt="28.05.2022"/>
                <Email
                    subject="Buchung – 2 Nächte – 02.06.2022-04.06.2022"
                    text="Hallo Frau Wayne,
hiermit bestätigen wir ihre Buchung in unserem Hotel.
Buchungsdetails:
Linda Wayne
Checkin: 02.06.2022 ab 14:00 Uhr
Checkout: 04.06.2022 bis 10:00 Uhr
2 Nächte Suite Zimmernummer 5
2x Premium Frühstücksbuffet
-
-
Wir wünschen Ihnen einen angenehmen Aufenthalt.
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="l-wayne@***.de"
                    sentAt="28.05.2022"/>
                <Email
                    subject="Buchung – 2 Nächte – 02.06.2022-04.06.2022"
                    text="Hallo Herr Organa,
hiermit bestätigen wir ihre Buchung in unserem Hotel.
Buchungsdetails:
Marvin Organa
Checkin: 02.06.2022 ab 14:00 Uhr
Checkout: 04.06.2022 bis 10:00 Uhr
2 Nächte Business Zimmernummer 14
2x Frühstücksbuffet
-
-
Wir wünschen Ihnen einen angenehmen Aufenthalt.
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="marvin-organa@***.de"
                    sentAt="22.05.2022"/>
                <Email
                    subject="Buchung – 2 Nächte – 02.06.2022-04.06.2022"
                    text="Hallo Frau Gump,
hiermit bestätigen wir ihre Buchung in unserem Hotel.
Buchungsdetails:
Wiebke Gump
Checkin: 02.06.2022 ab 14:00 Uhr
Checkout: 04.06.2022 bis 10:00 Uhr
2 Nächte – Suite Zimmernummer 6
2x Premium Frühstücksbuffet
-
-
Wir wünschen Ihnen einen angenehmen Aufenthalt.
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="w.gump@***.de"
                    sentAt="14.05.2022"/>
                <Email
                    subject="Buchung – 2 Nächte – 02.06.2022-04.06.2022"
                    text="Hallo Herr McFly,
hiermit bestätigen wir ihre Buchung in unserem Hotel.
Buchungsdetails:
Wolfram McFly
Checkin: 02.06.2022 ab 14:00 Uhr
Checkout: 04.06.2022 bis 10:00 Uhr
2 Nächte – Suite Zimmernummer 4
2x Premium Frühstücksbuffet
-
-
Wir wünschen Ihnen einen angenehmen Aufenthalt.
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="wolfram.mcfly@***.com"
                    sentAt="12.05.2022"/>
                <Email
                    subject="Büsche vor der Hotellobby schneiden"
                    text="Hallo Frau Everdeen,
wie telefonisch besprochen hier die Beauftragung:
-
Auftragsbeschreibung: Büsche Schneiden vor der Hotellobby
-
Wann: 02.06.2022 16:30 Uhr
-
Kostenvoranschlag Ihrerseits: 270 Euro
-
-
Mit freundlichen Grüßen
Lars Holgersson
-
Hotel Goldener Elefant
Am Steine 1, 31134 Hildesheim
www.goldener-elefant.coh.app
05171 7690480"
                    to="gärtnerei-everdeen@***.de"
                    sentAt="12.05.2022"/>
            </>
        }
        return <div className="background">
            <div className="header">
                <div className="wrapper">
                    <img src={Logo}/>
                    <h1>Postfach - gesendet</h1>
                </div>
            </div>
            {content}
        </div>;
    }
}

export default Index;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <Index/>
</React.StrictMode>);